import { NextSeo } from 'next-seo';
import { Html } from 'next/document';
import Head from 'next/head';
import React from 'react';

import { WEB_LINK } from '@common/constants';
import { HomePage } from '@modules/home/HomePage';

const SEO_KEYWORDS =
  'Property maintenance, Boiler repair, Emergency plumber, Gas safety checks, Electrical repairs, Appliance repair, Heating services, Drainage specialists, Handyman services, Locksmith London, Furniture assembly, Landlord certificates, Home repairs, Gas engineers, Lighting installation, Blocked drains, Emergency electrician, PAT testing, Fuse box repairs, London handyman';
const Home = () => {
  return (
    <>
      <Head>
        {/* PWA primary color */}
        <link
          href="https://fonts.googleapis.com/css2?family=Arimo:wght@400;700&family=Plus+Jakarta+Sans:wght@200;400;500;600;700;800&display=swap"
          rel="stylesheet"
        />
        <meta name="keywords" content={SEO_KEYWORDS} />
      </Head>
      <NextSeo
        title={'Emergency Boiler Repair London | Plumber London | Qeeper'}
        description={
          'Need an Expert Heating Engineer, Plumber, Electrician, Handyman, Appliance Engineer or Locksmith in London? Contact Qeeper to book your fast and reliable home repair today!'
        }
        canonical={WEB_LINK}
        additionalMetaTags={[
          {
            name: 'keywords',
            content: SEO_KEYWORDS,
          },
        ]}
        openGraph={{
          url: WEB_LINK,
          title: 'Emergency Boiler Repair London | Plumber London | Qeeper',
          description:
            'Need an Expert Heating Engineer, Plumber, Electrician, Handyman, Appliance Engineer or Locksmith in London? Contact Qeeper to book your fast and reliable home repair today!',
        }}
      />
      <HomePage />
    </>
  );
};

Home.getLayout = (page: any) => <Html lang="en">{page}</Html>;

export default Home;
