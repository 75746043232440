import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';

import { cx } from '@emotion/css';

const Lottie = dynamic(() => import('react-lottie-player'), {
  ssr: false,
});
interface LottieState {
  story?: any;
}

export const OurStory = ({ styles }: any) => {
  const [animationData, setAnimationData] = useState<LottieState>();
  useEffect(() => {
    import('public/lottie/our-story.json').then((res) =>
      setAnimationData((state) => ({ ...state, story: res.default })),
    );
  }, []);
  return (
    <section className={cx(styles.sec, styles.story_sec)}>
      <div className={cx(styles.container, styles.inner)}>
        <div className={styles.row}>
          <div className={cx(styles.our_story_block, styles.text_center)}>
            <div className={cx(styles.heading_blk, styles.w100)}>
              <h2 className={cx(styles.h2, styles.text_center)}>
                Why choose Qeeper?
              </h2>
            </div>
            <h6 className={cx(styles.h6, styles.our_stody_edsc)}>
              Dealing with a boiler breakdown on a chilly January day is
              nobody’s idea of fun. At Qeeper, we believe property maintenance
              shouldn’t be slow, stressful, or complicated. That’s why we
              created Qeeper, to make property care seamless and hassle-free.
            </h6>
            <h6 className={cx(styles.h6, styles.our_stody_edsc)}>
              With over 20,000 repairs completed in London, Edinburgh, and
              Brighton, Qeeper has established a reputation for reliability. Our
              services range from heating engineers resolving hot water issues
              to locksmiths assisting those locked out of their homes.
            </h6>
            <h6 className={cx(styles.h6, styles.our_stody_edsc)}>
              Our team of skilled tradespeople is dedicated to serving the
              entire London area. If you’re looking for a dependable property
              maintenance provider that delivers top quality work and
              outstanding customer service, Qeeper is the name to trust.
            </h6>
            <h6 className={cx(styles.h6, styles.our_stody_edsc)}>
              At Qeeper, we offer a comprehensive range of services, including
              Heating Engineers, Plumbers, Drainage Specialists, Electricians,
              Locksmiths, Handyman Services, Decorators, Appliance Engineers,
              Landlord Certificates, Fire Risk Assessors, and Glazing
              Specialists.
            </h6>
            <h6 className={cx(styles.h6, styles.our_stody_edsc)}>
              We’re also here for you in emergencies, providing prompt
              assistance from emergency plumbers, heating engineers, locksmiths,
              and electricians across London.
            </h6>
            <div className={styles.our_story_img}>
              <Lottie
                animationData={animationData?.story}
                speed={1}
                loop
                style={{ maxWidth: '450px', margin: '0px auto' }}
                play></Lottie>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
