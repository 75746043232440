import Image from 'next/image';
import React from 'react';

import { cx } from '@emotion/css';

interface Props {
  title: string;
  desc: string;
  mainImageSrc: string;
  iconImageSrc: string;
  styles: any;
}
const ImageComponent = (props: Props) => {
  const { styles, title, desc, mainImageSrc, iconImageSrc } = props;
  return (
    <div className={styles.service_card}>
      <div className={styles.service_img}>
        <Image
          src={mainImageSrc}
          alt={`${title} image`}
          layout="fill"
          objectFit="cover"
          className={styles.img}
        />
      </div>
      <div className={styles.service_content}>
        <div className={styles.service_icon}>
          <div className={styles.img}>
            <Image
              src={iconImageSrc}
              alt={`${title} icon`}
              height={30}
              width={30}
            />
          </div>
        </div>
        <h4 className={cx(styles.h4, styles.service_name)}>{title}</h4>
        <p className={cx(styles.p, styles.service_desc)}>{desc}</p>
      </div>
    </div>
  );
};

export const Services = ({ styles }: any) => {
  return (
    <section className={cx(styles.sec, styles.service_sec)}>
      <div className={cx(styles.container, styles.inner)}>
        <div className={styles.row}>
          <div className={styles.heading_blk}>
            <p className={styles.heading_tag}>Services we offer</p>
            <h2 className={cx(styles.h2, styles.rb_g)}>
              How can we
              <br /> help?
            </h2>
          </div>
        </div>
        <div className={cx(styles.row, styles.center, styles.spaceb)}>
          <div className={cx(styles.first_col, styles.service_col)}>
            <ImageComponent
              title="Heating & gas"
              desc="Installation and repair of all heating systems, gas safety checks and boiler servicing."
              mainImageSrc="/images/boiler.png"
              iconImageSrc="/images/icons/gas-icon.svg"
              styles={styles}
            />

            <ImageComponent
              title="Appliance repair"
              desc="Washing machines, dishwashers, electric ovens, dryers, fridges and more."
              mainImageSrc="/images/appliance-repair.jpg"
              iconImageSrc="/images/icons/appliance-icon.svg"
              styles={styles}
            />
          </div>
          <div className={cx(styles.second_col, styles.service_col)}>
            <ImageComponent
              title="Plumbing"
              desc="Repairing cold water issues with leaks, taps, sinks, baths, showers, and toilets."
              mainImageSrc="/images/plumbing.png"
              iconImageSrc="/images/icons/plumbing-icon.svg"
              styles={styles}
            />
            <ImageComponent
              title="Electrical"
              desc="Repairs and fault finding for electrical issues such as fuse boxes, lighting, sockets, and wiring."
              mainImageSrc="/images/electrical.jpg"
              iconImageSrc="/images/icons/electrical-icon.svg"
              styles={styles}
            />
            <ImageComponent
              title="Landlord certificates"
              desc="Gas Safety Certificate, EICR, PAT test, and Energy Performance Certificates."
              mainImageSrc="/images/certificate.jpg"
              iconImageSrc="/images/icons/certificate-icon.svg"
              styles={styles}
            />
          </div>
          <div className={cx(styles.third_col, styles.service_col)}>
            <ImageComponent
              title="Drainage"
              desc="Clearing blockages to internal or external drains and pipework. "
              mainImageSrc="/images/drainage.jpg"
              iconImageSrc="/images/icons/drainage-icon.svg"
              styles={styles}
            />
            <ImageComponent
              title="Handyman"
              desc="Odd jobs, furniture assembly, making small general repairs inside the home."
              mainImageSrc="/images/handyman.jpg"
              iconImageSrc="/images/icons/handyman-icon.svg"
              styles={styles}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
